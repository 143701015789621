main {
  display: block;
  flex: 1 0 auto;
  min-height: 70vh;
}

header, footer {
  flex-shrink: 0;
}

#__next {
  display: flex;
  flex-direction: column;
}

.screenReaderText {
  border: 0;
  clip: 'rect(1px, 1px, 1px, 1px)';
  clip-path: inset(50%);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
  word-wrap: normal;
}
